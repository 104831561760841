.quoteResult {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  align-self: stretch;
  justify-content: space-between;
}

.blockContent {
  width: 100%;
}

.blockContent h4 {
  margin-bottom: 2rem;
  text-align: center;
}

.paymentFrequency {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  text-align: left;
}

.quoteResultContainer {
  display: flex;
  flex-direction: column;
  gap: var(--gap-set-giga);
  text-align: center;
}

.getInsuranceButton {
  min-height: 4.5rem !important;
}

@media (min-width: 1025px) {
  .block {
    padding: var(--padding-block-vertical-secondary)
      var(--padding-block-horizontal);
  }
}
