.dateSelector {
  width: 100%;
  cursor: pointer;
  border-radius: var(--border-radius-card-small);
  background-color: var(--picker-onboarding);
  padding: 1rem;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  position: relative;
}

.dateSelectorContent {
  display: flex;
  text-align: left;
  justify-content: space-between;
  font-size: var(--font-body-small);
  font-weight: 700;
}

.calendarIcon {
  width: 2rem;
}

.startDateContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.startDateText {
  font-size: var(--font-body-tiny);
}

@media (min-width: 1025px) {
  .startDateContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .startDateText {
    font-size: var(--font-body-small);
  }
}
