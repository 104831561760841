.contentContainer {
  margin-top: 1rem;
}

.infoBlock {
  background-color: var(--background-brand-fourth2);
  border-radius: var(--border-radius-tertiary);
  padding: var(--padding-large);
  color: var(--text);
  font-weight: 700;
}

.infoBlock p:nth-child(1) {
  font-size: var(--font-body-small);
  opacity: 0.7;
}

.infoBlock p:nth-child(2) {
  margin-top: 0.75rem;
  font-size: var(--font-header-four-size);
}

.infoBlock p:nth-child(3) {
  margin-top: 0.75rem;
  font-size: var(--font-body-tiny);
  font-weight: 500;
}

.infoBlockContainer {
  display: grid;
  margin-top: 2rem;
  margin-bottom: 1.5rem;
  gap: 0.5rem;
}

@media (min-width: 768px) {
  .infoBlockContainer {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      'first second'
      'third third';
  }

  .infoBlockContainer > *:nth-child(1) {
    grid-area: first;
  }
  .infoBlockContainer > *:nth-child(2) {
    grid-area: second;
  }
  .infoBlockContainer > *:nth-child(3) {
    grid-area: third;
  }
}

@media (min-width: 1025px) {
  .infoBlockContainer {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: 'first second third';
  }
}

@media (min-width: 1441px) {
  .contentContainer {
    margin-top: 1.5rem;
  }
}
