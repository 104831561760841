.section {
  position: relative;
  display: grid;
  gap: 2rem;
  border-radius: var(--border-radius-primary);
  background-color: var(--background-primary);

  padding: 1rem;
  color: var(--text-primary);
}

.image {
  width: 100%;
}

.column1 .column2 {
  order: 1;
}

.content {
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
  padding: 0 1rem 1rem 1rem;
}

.paragraph {
  font-size: 1rem;
  color: var(--text-secondary);
}

.list {
  padding-left: 0.75rem;
  list-style-type: disc;
  list-style-position: inside;
  color: var(--text-secondary);
}

@media (min-width: 1025px) {
  .section {
    padding: 1.5rem;
    gap: 0.5rem;
    grid-template-columns: repeat(2, 1fr);
  }

  .orderLast {
    order: 2;
  }
}

@media (min-width: 1441px) {
  .section {
    padding: 2.5rem;
  }
}
